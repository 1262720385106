import React, { useEffect, useMemo } from "react"
import { graphql } from "gatsby"
import config from "../globals/variables"
import './index.scss'
import Article from "../components/pages/index/article"
import Transition from '../components/transition/transition'
import shuffle from '../helpers/shuffle'

const IndexPage = ({ data }) => {

  const posts = useMemo(() => {
    return shuffle(data.wpgraphql.posts.nodes)
  }, [data])


  useEffect(() => {
    // Make sure, page starts at the top!
    const main = document.querySelector('main')
    if(main) main.scrollTop = 0

  }, [])

  return (
    <Transition>
      <div className="projects index">
        {
          posts.map((post, i) => {
            
            return (
              <Article 
              key={i} 
              post={post} 
              selected={config.selected} 
              />
            )
          })
        }

      </div>  
    </Transition>
)}

export default IndexPage


export const indexQuery = graphql`
  query indexQuery {
    wpgraphql{
      posts(first: 100, where: {orderby: {field: DATE, order: ASC}}) {
        nodes {
          title
          slug
          categories{
            nodes{
              slug
            }
          }
          project {
            preview {
              __typename
              ... on WPGraphQL_Post_Project_Preview_Image {
                ...Image
              }
              ... on WPGraphQL_Post_Project_Preview_Video {
                video {
                  mediaItemUrl
                  mediaDetails {
                    width
                    height
                  }
                }
                image {
                  altText
                  mediaDetails{
                    width
                    height
                    sizes{
                      sourceUrl
                      width
                      height
                    }
                  }
                }
              }
            }
            hero {
              __typename
              ... on WPGraphQL_Post_Project_Hero_Image {
                image{
                  altText
                  mediaDetails {
                    sizes{
                      sourceUrl
                      width
                      height
                    }
                  }
                }
              }
              ... on WPGraphQL_Post_Project_Hero_Video {
                video {
                  mediaItemUrl
                }
                image{
                  altText
                  mediaDetails {
                    sizes{
                      sourceUrl
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
   
  }
`
