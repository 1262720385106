// Setup an array of random number
// To avoid unescessary rerendering on transition when calling it inside component
const randNumArray = []

for(let i = 0; i < 300; i++){
    randNumArray.push(Math.random())
}

const shuffle = (a) => {
    if(!a) return [false]
    let b = [...a];
    for (let i = b.length - 1; i > 0; i--) {
        // const j = Math.floor(Math.random() * (i + 1));
        // const j = Math.floor((randNumArray[i] || Math.random()) * (i + 1));
        const j = Math.floor((randNumArray[i]) * (i + 1));
        [b[i], b[j]] = [b[j], b[i]];
    }
    return b;
}

export default shuffle

