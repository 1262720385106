import React, { useState } from 'react'
// import 'lazysizes'
// import 'lazysizes/plugins/attrchange/ls.attrchange';
// import 'lazysizes/plugins/parent-fit/ls.parent-fit';
// import 'lazysizes/plugins/respimg/ls.respimg.js';

import './lazysizes.scss'

export default ({ image, preload, style, factor, postTitle }) => {
    // console.log('Image', image) 

    if(!image.mediaDetails) return <></>

    const sizes = image.mediaDetails.sizes.sort(function(a, b) {
        return a.width - b.width;
    })
    if(!sizes){
        console.log('NO THUMBNAILS ARE AVAILABLE')
        return null
    }

    const aspectRatio = sizes[sizes.length-1].width / sizes[sizes.length-1].height
    const padding = `${1/aspectRatio*100}%`
    // const loading = preload ? "lazypreload" : ""
    const alt = image.alt_text ||  postTitle || ""

    let sources = []

    const devicePixelRatio = 2

    sizes.forEach((size) => {
        // if(size.width === "400") return false
        let width = size.width
        // if(factor) width = size.width*factor
        // sources.push(`${size.sourceUrl} ${width}w`) 
        sources.push(`${size.sourceUrl} ${width/devicePixelRatio}w`) 
    })
    // sources.push(`${image.mediaItemUrl} `) 

    // if(image.mediaItemUrl) sources.push(`${image.mediaItemUrl} 2500w`)

    if(!sources.length) console.log(image.mediaDetails, sources)

    const srcset = sources.join(', ')

    return (
        <div className="image__container" style={
            {
                ...style, 
                paddingBottom: padding,
                // paddingBottom: contain ? false : padding
            }
        }>
            <div className="image__wrapper">
                <img 
                    data-sizes="auto"
                    // data-srcset={srcset}
                    // data-expand="100"
                    // className={`lazyload ${loading}`}
                    // sizes={`
                    //     (min-width: 750px) 50vw,
                    //     100vw`}
                    srcSet={srcset}
                    src={image.mediaItemUrl}
                    alt={alt}
                />
            </div>
        </div>
    )
}


const LazyVideo = ({ image, video, postTitle }) => {

    const [ state, setState ] = useState({
        isLoaded: false
    })

    const alt = image.alt_text ||  postTitle || ""
    // console.log(image)

    const sizes = image.mediaDetails.sizes
    if(!sizes) return null

    const aspectRatio = sizes[sizes.length-1].width / sizes[sizes.length-1].height
    // console.log(data)
    const padding = `${1/aspectRatio*100}%`


    let sources = []

    sizes.forEach((size) => {
        if(size.width === "400") return false
        let width = size.width
        // if(factor) width = size.width*factor
        sources.push(`${size.sourceUrl} ${width}w`) 
    })

    if(image.sourceUrl) sources.push(image.sourceUrl)

    // const srcset = sources.join(', ')

    const dataLoaded = () => {
        setState({
            isLoaded: true
        })
    }

    return (
        <div className="image__container" style={
            {
                paddingBottom: padding
            }
        }>

            <div className="image__wrapper">
                <div style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    // background: "gainsboro"
                }}/>
                {/* TODO: REMOVE PLACEHOLDER IMAGE!! */}
                {/* <img 
                    data-sizes="auto"
                    data-expand="100"
                    // src={base64}
                    data-srcset={srcset}
                    alt={alt}
                    className={`lazyload`}
                /> */}
                <video 
                    style={{
                        opacity: state.isLoaded ? 1 : 0,
                    }}
                    src={video} 
                    autoPlay={true} 
                    loop={true} 
                    muted
                    playsInline
                    alt={alt}
                    onLoadedDataCapture={dataLoaded} 
                />
            </div> 
        </div>
    )
}

export { LazyVideo }
