import React from "react"
import TransitionLink from 'gatsby-plugin-transition-link'
import './article.scss'
import { exitTransition, entryTransition } from '../../transition/transition'
import LazySizes, { LazyVideo } from '../../image/lazysizes'


const Article = ({ post, onMouseOver }) => {

    // const [ state, setState ] = useState({
    //     hovered: false
    // })

    let ratio;
    const preview = post.project.preview ? post.project.preview[0] : false
    const hero = post.project.hero ? post.project.hero[0] : false

    const postTitle = post.title

    if(typeof preview === 'undefined') return false
    if(!preview.image && !preview.video && !hero.image && !hero.video) return false
    
    let section = false

    if(preview.__typename === "WPGraphQL_Post_Project_Preview_Image" || hero.__typename === "WPGraphQL_Post_Project_Hero_Image"){

        section = preview && preview.image ? preview : hero
        
        if(!section.image) return false
        const mediaDetails = section.image.mediaDetails
        const aspectRatio = mediaDetails.width / mediaDetails.height
        ratio = 1 > aspectRatio ? "portrait" : ""
    }

    if(!section.image && !section.video ) return false

    return(
        <div className={`article ${ratio}`}>
            <TransitionLink 
            to={`/${post.slug}/`}
            exit={exitTransition}
            entry={entryTransition}
            aria-label={post.title}
            >
                <Section section={section} postTitle={postTitle} />
            </TransitionLink>
        </div>
    )
}

const Section = ({section, postTitle}) => {

    const media = section

    if (media.__typename === "WPGraphQL_Post_Project_Hero_Image" || media.__typename === "WPGraphQL_Post_Project_Preview_Image"){
        
        if(!media.image.mediaDetails) return false

        return <LazySizes image={media.image} preload={false} factor={2} postTitle={postTitle}/>

    }else if(media.__typename === "WPGraphQL_Post_Project_Hero_Video" || media.__typename === "WPGraphQL_Post_Project_Preview_Video"){
        // console.log(media)
        if(!media.video) return false

        return <LazyVideo video={media.video.mediaItemUrl} image={media.image} postTitle={postTitle} />
    }
}

export default Article
